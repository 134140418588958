/* eslint-disable */

import { getSearchableMenus } from '@/navigation/vertical'
import store from '@/store'

// prettier-ignore
export default {
  pages: {
    key: 'title',
    title: 'Páginas',
    data: [
      ...getSearchableMenus(),
      // { title: 'Blog Detail', route: { name: 'pages-blog-detail', params: { id: 1 } }, icon: 'FileTextIcon', isBookmarked: false },
    ],
  },
  // sellers: {
  //   key: 'nomeFantasia',
  //   data: [
  //     ...store.state.hub.sellers.map(seller => ({ nomeFantasia: seller.dadoSeller.nomeFantasia, tenant: seller.id })),
  //   ],
  // },
}
