<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificacoes.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificações
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notificacoes.length }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!--Lista de notificacoes-->
      <b-list-group :key="listaKey">
        <b-list-group-item
          v-for="notificacao in notificacoes"
          :key="notificacao.id"
          class="d-flex align-items-center list-item-notification"
        >
          <feather-icon
            class="mr-1"
            :icon="getIcone(notificacao.tipoNotificacao)"
            size="24"
            stroke="#6d6d6d"
          />
          <b
            class="mr-auto title-notification"
          >{{ notificacao.mensagem }}
            <br>
            <span class="subtitle-notification">mensagem subtitulo</span>
          </b>
          <b-button
            class="btn-remove-notification btn-icon"
            @click="remove(notificacao.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="20"
              stroke="#cc0000"
            />
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </vue-perfect-scrollbar>
    <b-link />
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BLink, BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BLink,
    BListGroup,
    BListGroupItem,
    BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      listaKey: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notificacoes: [
        {
          id: '60df7222e5a37905bafdaa1f',
          createDate: '2021-07-02T17:08:02-03:00',
          tenant: 'GGDV20348559000114',
          operator: 'GGDV18180472000139',
          lida: false,
          tipoNotificacao: 'pedidoAprovado',
          idUsuario: 'GGDV18180472000139',
          incomingId: '10491727000172.904',
          mensagem: 'teste de mensagem',
          dataLido: '2021-05-27T20:45:11.000Z',
        },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      // this.notificacoes.push(...(await this.$http.get('/backend/v1/notificacao/GGDV18180472000139')).data.items)
      this.listaKey = Math.random()
    },
    // eslint-disable-next-line no-unused-vars
    remove(id) {
      // this.$http.delete(`/backend/v1/notificacao/${id}`)
      //   .then(() => {
      //     const notificacaoIndex = this.notificacoes.findIndex(notificacao => notificacao.id === id)
      //     this.notificacoes.splice(notificacaoIndex, 1)
      //   })
    },
    getIcone(tipoNotificacao) {
      // Pega o icone de acordo com o tipo de notificacao
      let icone
      switch (tipoNotificacao) {
        case 'pedidoAprovado':
          icone = 'ShoppingCartIcon'
          break
        default:
          icone = 'AlertTriangleIcon'
      }
      return icone
    },
  },
}
</script>

<style></style>
