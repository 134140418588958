var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-language",
      attrs: { variant: "link", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("feather-icon", {
                staticClass: "text-body",
                attrs: {
                  "badge-classes": "bg-danger",
                  icon: "HelpCircleIcon",
                  size: "21",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.go("portal")
            },
          },
        },
        [_c("span", [_vm._v(" " + _vm._s(_vm.$t("Acessar Portal")) + " ")])]
      ),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.go("linkFac")
            },
          },
        },
        [
          _c("span", [
            _vm._v(" " + _vm._s(_vm.$t("Perguntas e Respostas")) + " "),
          ]),
        ]
      ),
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function ($event) {
              return _vm.go("linkSuporte")
            },
          },
        },
        [_c("span", [_vm._v(" " + _vm._s(_vm.$t("Novo Ticket")) + " ")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }