var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-language",
      attrs: { id: "dropdown-grouped", variant: "link", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("b-img", {
                attrs: {
                  src: _vm.currentLocale.img,
                  height: "14px",
                  width: "22px",
                  alt: _vm.currentLocale.locale,
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    _vm._l(_vm.locales, function (localeObj) {
      return _c(
        "b-dropdown-item",
        {
          key: localeObj.locale,
          on: {
            click: function ($event) {
              return _vm.setLocale(localeObj.locale)
            },
          },
        },
        [
          _c("b-img", {
            attrs: {
              src: localeObj.img,
              height: "14px",
              width: "22px",
              alt: localeObj.locale,
            },
          }),
          _c("span", { staticClass: "ml-50" }, [
            _vm._v(_vm._s(_vm.$t(localeObj.name))),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }