<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p
          class="user-name font-weight-bolder mb-0"
          data-test="UsernameMenu"
        >
          {{ fullNameUser || userNameUser }}
        </p>
        <!-- <span class="user-status">{{ nomeFantasiaEmpresaLogada }}</span> -->
      </div>
      <b-avatar
        size="40"
        :src="avatarUser"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!fullNameUser"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-text
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <span><b>{{ $t('Usuário') }}: </b>{{ fullNameUser }}</span>
    </b-dropdown-text>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      data-test="ResetPasswordButton"
      @click="resetPassword"
    >
      <span>{{ $t('Alterar senha') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="isElectron"
      link-class="d-flex align-items-center"
      data-test="ChangeUserButton"
      @click="changeUser"
    >
      <span>{{ $t('Trocar usuário') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      data-test="LogoutButton"
      @click="exit"
    >
      <span>{{ $t('Sair') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownText,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import isElectron from 'is-electron'

export default {
  components: {
    BNavItemDropdown,
    BDropdownText,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  mixins: [],
  data() {
    return {
      // userData: getUserData(),
      loggedUser: getUserData(),
      isElectron: isElectron(),
    }
  },
  computed: {
    avatarUser() {
      return this.loggedUser?.avatar
    },
    userNameUser() {
      return this.loggedUser?.username
    },
    fullNameUser() {
      return this.loggedUser?.fullName
    },
  },
  methods: {
    resetPassword() {
      this.$router.push({ name: 'auth-reset-password' })
    },

    async changeUser() {
      this.doLogout(false)
    },

    async exit() {
      this.doLogout(true)
    },

    async doLogout(electronCloseWindow) {
      // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      // Setando o estilo do operador para vazio, a aplicação não é mais exibida, e o spinning vai ficar no lugar.
      await this.$store.dispatch('app/setEstiloOperador', {})
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'block'
      }
      useJwt.logout()

      // Reset ability
      this.$ability.update(initialAbility)

      if (this.isElectron) {
        if (electronCloseWindow) {
          window.electronAPP.quit()
        } else {
          window.location.href = window.electronAPP.loadURL
        }
      } else {
        // Usando $router.go para resetar todo o estado da aplicação (Vuex)
        this.$router.go()
      }
    },
  },
}
</script>
