var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-notification mr-25",
      attrs: { "menu-class": "dropdown-menu-media", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c("feather-icon", {
                staticClass: "text-body",
                attrs: {
                  badge: _vm.notificacoes.length,
                  "badge-classes": "bg-danger",
                  icon: "BellIcon",
                  size: "21",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("li", { staticClass: "dropdown-menu-header" }, [
        _c(
          "div",
          { staticClass: "dropdown-header d-flex" },
          [
            _c("h4", { staticClass: "notification-title mb-0 mr-auto" }, [
              _vm._v(" Notificações "),
            ]),
            _c("b-badge", { attrs: { pill: "", variant: "light-primary" } }, [
              _vm._v(" " + _vm._s(_vm.notificacoes.length) + " "),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "scrollable-container media-list scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings, tagname: "li" },
        },
        [
          _c(
            "b-list-group",
            { key: _vm.listaKey },
            _vm._l(_vm.notificacoes, function (notificacao) {
              return _c(
                "b-list-group-item",
                {
                  key: notificacao.id,
                  staticClass:
                    "d-flex align-items-center list-item-notification",
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: {
                      icon: _vm.getIcone(notificacao.tipoNotificacao),
                      size: "24",
                      stroke: "#6d6d6d",
                    },
                  }),
                  _c("b", { staticClass: "mr-auto title-notification" }, [
                    _vm._v(_vm._s(notificacao.mensagem) + " "),
                    _c("br"),
                    _c("span", { staticClass: "subtitle-notification" }, [
                      _vm._v("mensagem subtitulo"),
                    ]),
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-remove-notification btn-icon",
                      on: {
                        click: function ($event) {
                          return _vm.remove(notificacao.id)
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "Trash2Icon",
                          size: "20",
                          stroke: "#cc0000",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("b-link"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }