var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canViewVerticalNavMenuLink(_vm.item)
    ? _c(
        "li",
        {
          staticClass: "nav-item",
          class: {
            active: _vm.isActive,
            disabled: _vm.item.disabled,
          },
          attrs: { title: _vm.item.title },
        },
        [
          _c(
            "b-link",
            _vm._b(
              { staticClass: "d-flex align-items-center" },
              "b-link",
              _vm.linkProps,
              false
            ),
            [
              !_vm.item.iconPack || _vm.item.iconPack === "feather"
                ? _c("feather-icon", {
                    attrs: { icon: _vm.item.icon || "CircleIcon" },
                  })
                : _vm._e(),
              _vm.item.iconPack === "bootstrap"
                ? _c("b-icon", { attrs: { icon: _vm.item.icon } })
                : _vm._e(),
              _c("span", { staticClass: "menu-title text-truncate" }, [
                _vm._v(_vm._s(_vm.t(_vm.item.title))),
              ]),
              _vm.item.tag
                ? _c(
                    "b-badge",
                    {
                      staticClass: "mr-1 ml-auto",
                      attrs: {
                        pill: "",
                        variant: _vm.item.tagVariant || "primary",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }