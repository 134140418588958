<template>
  <b-nav-item-dropdown
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <feather-icon
        badge-classes="bg-danger"
        class="text-body"
        icon="HelpCircleIcon"
        size="21"
      />
    </template>

    <b-dropdown-item @click="go('portal')">
      <span>
        {{ $t('Acessar Portal') }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item @click="go('linkFac')">
      <span>
        {{ $t('Perguntas e Respostas') }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item @click="go('linkSuporte')">
      <span>
        {{ $t('Novo Ticket') }}
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

const links = {
  portal: 'https://echope.freshdesk.com/support/login',
  linkFac: 'https://echope.freshdesk.com/support/home',
  linkSuporte: 'https://echope.freshdesk.com/support/tickets/new',
}

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  mixins: [],
  data() {
    return {}
  },
  computed: {},
  methods: {
    go(link) {
      window.open(links[link], '_blank')
    },
  },
}
</script>
