var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "clearfix mb-0" }, [
    _c(
      "span",
      { staticClass: "float-md-left d-block d-md-inline-block mt-25" },
      [
        _vm._v(" COPYRIGHT © " + _vm._s(new Date().getFullYear()) + " "),
        _c(
          "b-link",
          {
            staticClass: "ml-25",
            attrs: {
              href: "https://1.envato.market/pixinvent_portfolio",
              target: "_blank",
            },
          },
          [_vm._v("Pixinvent")]
        ),
        _c("span", { staticClass: "d-none d-sm-inline-block" }, [
          _vm._v(", All rights Reserved"),
        ]),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "float-md-right d-none d-md-block" },
      [
        _vm._v("Hand-crafted & Made with "),
        _c("feather-icon", {
          staticClass: "text-danger stroke-current",
          attrs: { icon: "HeartIcon", size: "21" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }